import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { PaginationModel } from "../models/interfaces/pagination.model";
import { Observable } from "rxjs";
import { IRequestOptions } from "./utils.service";
import { CardItem } from "../models/interfaces/card-item.model";
import { ExperienceModel } from "../models/interfaces/experience.model";

@Injectable({
  providedIn: 'root'
})

export class ExperienceService extends BaseService {

  constructor( private http: HttpClient) {
    super();
  }


  /** GET RANDOM EXPERIENCES **/
  getRandomExperiences(pagination: PaginationModel): Observable<ExperienceModel[]>{
    const endpoint: string = this.getEndpoint( BaseService.ENDPOINT_NAME_GET_RANDOM_EXPERIENCE );
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('Skip' , pagination?.take * (pagination?.page - 1));
    queryParams = queryParams.append('Take' , pagination?.take);
    queryParams = queryParams.append('Seed' , pagination?.seed);
    let options = {params:queryParams, withCredentials:true} as IRequestOptions;
    return this.http.get<ExperienceModel[]>(endpoint , options );
  }


  /** Get Experience Details **/
  getExperienceDetails(id: number): Observable<ExperienceModel>{
    const endpoint: string = this.getEndpoint( BaseService.ENDPOINT_NAME_GET_EXPERIENCE_DETAIL );
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('id', id);
    let options = {params:queryParams, withCredentials:true} as IRequestOptions;
    return this.http.get<ExperienceModel>(endpoint , options );
  }

    /** Add to favorites **/
    addToFavorites(id: number): Observable<any>{
      const endpoint: string = this.getEndpoint( BaseService.ENDPOINT_NAME_ADD_TO_FAVORITES_EXPERIENCE );
      let options = { withCredentials:true } as IRequestOptions;
      return this.http.post(endpoint , {
        entityId: id
      } , options );
    }

}
